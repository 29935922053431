<template>
    <div class="pc_login white-back">
        <div class="flex-column flex-r-center" style="padding: 100px;">
            <div>
                <el-button type="warning" @click="bind">（已有账号，绑定微信）</el-button>
            </div>
            <div>
                <el-button style="width:195px;margin-top: 50px;" @click="register" type="primary">注册账号</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {pc_wx_login} from '@/request/api'

    export default {
        name: "pclogin.vue",
        data() {
            return {
                unionid: '',
                avatar:''
            }
        },
        created() {
            let {code} = this.$route.query;
            pc_wx_login({code}).then((res) => {
                if (res.code == 1) {
                    console.log(res)
                    if (res.data.headimgurl) {
                        this.unionid = res.data.unionid;
                        this.avatar=res.data.headimgurl;
                    } else {
                        this.$message.success('登录成功');
                        localStorage.setItem('ylsToken', res.data.token)
                        localStorage.setItem('userId', res.data.user_id);
                        localStorage.setItem('userInfo', JSON.stringify(res.data));
                        this.$router.push({path: '/'});
                    }
                } else {
                    // window.location = "https://yls.jxsxkeji.com/api.php/user/weixin_qrcode";
                }

            })

        },
        methods: {
            bind(){
                let {unionid,avatar}=this;
                this.$router.push({path: '/bind', query: {unionid,avatar}})
            },
            register() {
                let {unionid,avatar}=this;
                this.$router.push({path: '/register', query: {unionid,avatar}})
            }
        }
    }
</script>

<style scoped>

</style>
